import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {LoaderService} from '@app/core/services';
import {AsyncPipe} from '@angular/common';
import {LoaderComponent} from '@app/shared/components/loader/loader.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AsyncPipe, LoaderComponent],
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'Kartam-CPL-FrontEnd';
  loading = false;

  constructor(
    public loaderService: LoaderService
  ) {
  }
}
