import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {Constants} from "../constants";

export class JwtInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const access_token = Constants.ACCESS_TOKEN()?.replaceAll('"', '');
    const isTokenRequired = req.context.get(Constants.TOKEN_REQUIRED);
    if (access_token && isTokenRequired) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${access_token}`,
          "ngrok-skip-browser-warning": "69420",
        }
      })
    }
    return next.handle(req);
  }
}
